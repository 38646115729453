@import "~sanitize.css/sanitize.css";
@import "./fontiran.css";
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  background: #ffffff;
  color: #2a2a32;
  min-height: 100vh;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-family: sans-serif, monospace;
  overflow-x: hidden;
  text-align: justify;
}

h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
body,
button {
  font-family: sans-serif, monospace;
}

h1 {
  font-weight: bold;
}

:lang(fa) {
  direction: rtl;
  font-family: "IRANSans", serif;
}

.slick-slider,
.slick-slider * {
  direction: ltr;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
